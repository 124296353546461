import { TranscriptionContent } from './common';

export interface UserJobsModel {
  userId: string;
  jobId: string; // unique job id
  createdAt: string; // Timestamp for when the whole process started
  updatedAt: string; // Timestamp for last time process updated
  description?: string; // description of job
  config: JobConfig; // job configuration
  // Job status for each transcription and speaker recognition will be retained as follow:
  // 1- If job start time is empty then its not started yet --> Accepted
  // 2- If job is started(start time has a value) but end time is empty ---> Executing
  // 3- If Job end time is not empty --> completed (time difference will be the duration of job execution)
  inputAudioFile?: string; // Uri of input audio file in s3 bucket; may be empty because we create entry before we update file to s3
  inputAudioFileSize?: number; // Size of input audio file in s3 bucket; may be empty because we create entry before we update file to s3
  inputAudioFileDuration?: number; // Duration of input audio file in seconds
  transcriptionJobStartTime?: string; // transcription start time for job - if empty means have not started yet
  transcriptionJobEndTime?: string; // transcription end time - if empty means have not finished yet
  transcriptionJobOutputFile?: string; // Uri of transcription output file in s3 bucket
  transcriptionJobOutputFileSize?: number; // Size of transcription output file in s3 bucket
  speakerRecognitionJobStartTime?: string; // speaker recognition job start time -  if empty means have not started yet
  speakerRecognitionJobEndTime?: string; // speaker recognition end time - if empty means have not finished yet
  speakerRecognitionJobOutputFile?: string; // Uri of speaker recognition output file in s3 bucket
  tTL?: number; // in UNIX epoch seconds format
  userEmail?: string; // user email - to send out email when job is finished
  userName?: string; // user name - to send out email when job is finished
  transcriptionDoneEmailSent: boolean; // flag to see if transcription done email was already sent out
  status: JobStatus; // shows job status
}

export type Job = UserJobsModel & {
  content?: TranscriptionContent; // placeholder, actual content to highlight the output of the system; undefined before completed
  status?: string;
  originalFilePresignedUrl?: string;
};

export interface JobConfig {
  language?: string;
  wordTimestamp: boolean;
  speakerRecognition: boolean;
}

export enum JobStatus {
  PROCESSING = 'processing', // specified when the job is created and passed to the model
  COMPLETED = 'completed', // after the model is done processing the job and received by sqs
  ERROR = 'error', // if the model fails to process the job due to incompatible language
  STALED = 'staled', // if the job takes more then 4 hours to process (assigned dynamicaly)
  CANCELLED = 'cancelled' // id the user cancels the job via an API call
}

export type Word = {
  text: string;
  probability: number;
};

export type Segment = {
  speaker: string; // maybe and object if more info needed
  start: number;
  end: number;
  words: Array<Word>;
};

export interface JobCreateParams {
  file: {
    name: string;
    size: number;
    type: string;
  };
  description?: string;
  language?: string;
  wordTimestamp: boolean;
  speakerRecognition: boolean;
  email?: string;
  name?: string;
  duration?: number;
}

export interface JobStatusEmailParams {
  username?: string;
  jobDescription?: string;
  jobId: string;
  to: string;
  status: JobStatus;
}

export type JobsGetAllResponse = {
  jobs: Job[];
  lastEvaluatedKey?: Record<string, string>;
};

export type JobGetResponse = {
  job: Job;
  url: string;
  content: TranscriptionContent;
};

export type JobCreateResponse = {
  jobId: string;
  upload: {
    url: string;
    fields: Record<string, string>;
  };
};

export interface TranscriptionTextConfig {
  showSpeakers: boolean;
  showTimestamps: boolean;
}

export type JobDeleteResponse = 'deleted' | 'failed' | 'cancelled';

export type JobDetailsResponse = {
  job: Job;
  content: TranscriptionContent;
  url: string | null;
};

export enum JobLanguages {
  GERMAN = 'de',
  FRENCH = 'fr',
  ENGLISH = 'en',
  ITALIAN = 'it',
  POLISH = 'pl',
  ROMANIAN = 'ro',
  HUNGARIAN = 'hu',
  SLOVAK = 'sk'
}
